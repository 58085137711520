import { useEffect,useState } from "react";
import "./RemoveModal.css";
import { groupGetAPI, postRemoveToGroupInContactPageAPI } from "../../services/servicesAPI";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import SmallLoaderComponent from "../../components/smallLoaderComponent/SmallLoaderComponent";
import LoaderComponent from "../../components/loaderComponent/LoaderComponent";
const RemoveModal = ({
  selectedIds,
  headingText,
  onClose,
  onSuccess,
  filters = [],
  onSelectVisibleFlag = false
}) => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [dropdownFlag,setDropdownFlag] = useState(false)
  const [groupData,setGroupData] = useState([])
  const [selectedGroupId,setSelectedGroupId] = useState([])
  const [refreshPage,setRefreshPage] = useState(false)
  const [pagingData, setPagingData] = useState({ page: 1, limit: 1000 });
  const [smallLoaderFlag,setSmallLoaderFlag] = useState(false)
  const [filterData, setFilteredData] = useState({
    sortBy: "createdAt",
    order: "DESC",
  });
  const [loader,setLoader] = useState(false)

  useEffect(()=>{
    fetchAllGroupAPI(pagingData,filterData)

  },[])

  useEffect(() => {
    const handleClickOutside = (event) => {
      let target = event.target;
      let isInsideDropdown = target.closest('.rename-ct-tb-modal-content-content-2') 
      if (!isInsideDropdown) {
        setDropdownFlag(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); 

  const fetchAllGroupAPI = async (pagingData, filterData)=>{
    setSmallLoaderFlag(true)
    const response = await groupGetAPI(pagingData, filterData)
    console.log("response==============>",response?.data?.data?.result)
    if(response.success){
      // toast.success(response.message)
      let temp = response?.data?.data?.result.map((item)=>{
        item.isCheck = false
        return item
      })
      setGroupData(temp)

    }else{
      toast.error(response.message)
    }
    setSmallLoaderFlag(false)

  }

  const saveButtonPostApi = async ()=>{
    setLoader(true)

    let onlyGroupIds = selectedGroupId.map((item)=>{
      return item._id
    })

  let temp = {
    "selectAll":onSelectVisibleFlag?true:false,
    "groupIds":onlyGroupIds,
    "contactIds":selectedIds,
    filters:filters,
  }

    const response = await postRemoveToGroupInContactPageAPI(temp)

    console.log("response=====>",response)

    if(response.success){
      onSuccess()
      toast.success(response.message)

    }else{
      toast.error(response.message)
    }
    setLoader(false)
  }

  const handleSaveButton = async () => {
    if (selectedGroupId.length > 0) {
      await saveButtonPostApi()
      onClose();
    } else {
      setTimeout(() => {
        setErrorMessage(false);
      }, 500);

      setErrorMessage(true);
    }
  };

  const handleInput = (value)=>{
    setInputValue(value);
    if(value.length > 0){
      setDropdownFlag(true)
    }else{
      setDropdownFlag(false)
    }

   }

  const handleCheckIdIsOrNot = (value)=>{
    for(let i = 0; i<selectedGroupId.length ; i++){
      if(selectedGroupId[i]._id === value._id){
        return {boolValue:true,index:i}
      }
    }
    return {boolValue:false,index:-1}

  }


  const handleGroupIdSelected = (value,index)=>{

    let itemCheck = handleCheckIdIsOrNot(value)
    if(itemCheck.boolValue){
      selectedGroupId.splice(itemCheck.index,1)
      groupData[index].isCheck = false

    }else{
      groupData[index].isCheck = true
    
      selectedGroupId.push(value)
    }
    setRefreshPage(!refreshPage)
   }

   const removeGroupId = (item,index)=>{
    selectedGroupId.splice(index,1)
    item.isCheck = false
    setRefreshPage(!refreshPage)
   }

  return (
    <div className="rename-ct-tb-modal">
      {
        loader && <LoaderComponent />
      }
      <div className="rename-ct-tb-modal-content">
        <div
          className="rename-ct-tb-modal-content-close"
          onClick={() => {
            onClose();
          }}
        >
          <div><p>x</p></div>
        </div>
        <div className="rename-ct-tb-modal-content-content">
          <div className="rename-ct-tb-modal-content-content-1">
            <h3>{headingText}</h3>
          </div>
          <div className="rename-ct-tb-modal-content-content-2">
          {
               selectedGroupId.map((item)=>{
                return <span >{item?.name} <IoClose  onClick={()=>{removeGroupId(item)}} style={{marginLeft:"1vw",cursor:"pointer"}} />  </span>
              })
            }
            <input
            onFocus={()=>{
              setDropdownFlag(true)
            }}
              value={inputValue}rename-modal
              onChange={(e) => {
                handleInput(e.target.value)
                
              }}
              type="text"
              placeholder="Select group"
            />
            {
              dropdownFlag && <div className="rename-ct-tb-modal-content-content-2-dp">
                {
                  smallLoaderFlag && <SmallLoaderComponent />
                }
                <ul>
                  {
                    groupData.map((item,index)=>{
                      return <li
                      onClick={()=>{handleGroupIdSelected(item,index)}}
                      ><input checked={item?.isCheck} style={{marginRight:"0.7vw"}} type="checkbox" name="" id="" />{item?.name}</li>
                    })
                  }
                  
                </ul>

              </div>

            }
            {errorMessage ? (
              <div style={{ position: "absolute", bottom: "-15px",left:"0px" }}>
                <p
                  style={{
                    fontSize: "0.8em",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  you are not selected any group.
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="rename-ct-tb-modal-content-content-3">
            <button
              onClick={() => {
                onClose();
              }}
              style={{ color: "#404040", backgroundColor: "#E5E7EB" ,fontWeight:'500',fontSize:'1.1em'}}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleSaveButton();
              }}
              style={{ backgroundColor: "rgb(230,81,67)", color: "#ffffff" ,fontWeight:'600',fontSize:'1.1em'}}
            >
              Remove
            </button>
          </div>    
        </div>
      </div>
    </div>
  );
};

export default RemoveModal;
